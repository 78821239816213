import type { ApolloQueryResult } from '@apollo/client'
import { provideApolloClient, useMutation } from '@vue/apollo-composable'
import { DELETE_STORED_DOCUMENT } from '~/queries/documents'
import { UPDATE_USER } from '~/queries/user'
import {
  GET_CURRENT_USER,
  TOGGLE_TRACKDECHETS_AUTOSIGN,
  USERS_NEST_QUERY_LIST,
  USERS_QUERY_LIST,
  USERS_QUERY_UPDATE,
} from '~/queries/users'
import type {
  CurrentUserGraphql,
  Query,
  UpdateUserInput,
  UserFilterGraphql,
} from '~/types/graphql-backend-types/gql-types'
import type { User } from '~/types/user'
import apolloClient from '../composables/graphql'

provideApolloClient(apolloClient)

export const useUsersStore = defineStore('users', () => {
  const { query, mutate } = useGqlMikro()

  const users = ref<User[]>([])
  const user = ref() as Ref<CurrentUserGraphql>

  const getUsersAsOptions = computed(() => {
    return users.value.map((item) => {
      const function_name = item?.function ?? ''
      const function_suffix = function_name ? ` - ${function_name}` : ''
      return {
        label: `${item.first_name} ${item.last_name}${function_suffix}`,
        value: item.id,
      }
    })
  })

  async function loadUsers() {
    await apolloClient.resetStore()
    const { data } = await apolloClient.query({ query: USERS_QUERY_LIST })
    users.value = data.users || []
  }

  async function loadUsersNest(filters: UserFilterGraphql) {
    const { query } = useGqlMikro()
    const { data } = await query({ query: USERS_NEST_QUERY_LIST, variables: { filters } })
    return data
  }

  async function updateUserNest(user: UpdateUserInput) {
    const { mutate } = useGqlMikro()
    const { data, errors, validationError } = await mutate({ mutation: UPDATE_USER, variables: { input: user } })
    return { data, errors, validationError }
  }

  function filterUsers(ids: any) {
    return users.value.filter((u: any) => ids?.length && ids?.includes(u?.id))
  }

  function getUser(user_id: string): User {
    return users.value.find((u: any) => u?.id === user_id) || {} as User
  }

  async function updateUser(userId: string, pictureUrl: any) {
    const { mutate } = useMutation(USERS_QUERY_UPDATE)
    await mutate({ id: userId, _set: pictureUrl })

    const userIndex = users.value.findIndex((u: any) => u?.id === userId)
    if (userIndex !== -1) {
      users.value = users.value.map((user, index) => {
        if (index === userIndex) {
          const updatedUser = JSON.parse(JSON.stringify((user)))
          updatedUser.attachments = pictureUrl
          return updatedUser
        }
        return user
      })
    }
  }

  const isRecycler = computed(() => {
    return user.value?.client?.__typename === 'RecyclerGraphql'
  })

  const isProducer = computed(() => {
    return user.value?.client?.__typename === 'ProducerGraphql'
  })

  const isAdmin = computed(() => {
    return user.value?.user?.role === 'admin_app'
  })

  async function removePictureProfile(userId: string) {
    const { data } = await mutate({
      mutation: DELETE_STORED_DOCUMENT,
      variables: { input: { id: userId } },
    })

    return data?.deleteStoredDocument
  }
  async function getCurrentUser() {
    const { data } = await query({ query: GET_CURRENT_USER }) as ApolloQueryResult<Query>
    user.value = data.me

    return data?.me?.client ?? {}
  }

  async function toggleTrackDechetAutosign() {
    const { data } = await mutate({ mutation: TOGGLE_TRACKDECHETS_AUTOSIGN })
    return data
  }

  return {
    users,
    user,
    isRecycler,
    isProducer,
    isAdmin,
    getUsersAsOptions,
    loadUsers,
    filterUsers,
    loadUsersNest,
    updateUserNest,
    getUser,
    updateUser,
    getCurrentUser,
    toggleTrackDechetAutosign,
    removePictureProfile,
  }
})
