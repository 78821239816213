import { differenceBy, find } from 'lodash'
import apolloClient from '~/composables/graphql'
import { useGqlMikro } from '~/composables/useGqlMikro'
import {
  BO_COLLECTS_QUERY_LIST,
  CANCEL_COLLECT,
  COLLECTS_QUERY_LIST,
  COLLECTS_QUERY_SINGLE,
} from '~/queries/collects'
import {
  SERVICES_QUERY_DELETE,
  SERVICES_QUERY_INSERT,
  SERVICES_QUERY_UPDATE,
  SIGN_BSD,
  WASTE_SERVICE_RAW_MATERIALS_CONTAINER_DELETE,
  WASTE_SERVICE_RAW_MATERIALS_CONTAINER_DELETE_PRODUCER,
  WASTE_SERVICE_RAW_MATERIALS_CONTAINER_INSERT_PRODUCER,
  WASTE_SERVICE_RAW_MATERIALS_CONTAINER_UPDATE,
  WASTE_SERVICE_RAW_MATERIALS_CONTAINER_UPDATE_PRODUCER,
  WASTE_SERVICES_CONTAINERS_SUM_CO2,
  WASTE_SERVICES_CONTAINERS_SUM_QUANTITY,
} from '~/queries/services'
import { userStore } from '~/stores/user'
import type {
  BsdGraphql,
  CancelCollectInputGraphql,
  CollectBsdState,
  CollectGraphql,
  CollectsCollection,
  CollectStatus,
  IncidentGraphql,
  Maybe,
  Mutation,
  TrackdechetsBsdState,
} from '~/types/graphql-backend-types/gql-types'
import { AscDescGraphql } from '~/types/graphql-backend-types/gql-types'
import { camelToSnakeCase } from '~/utils/string'

export const useCollectsStore = defineStore('collects', () => {
  const { mutate } = useGqlMikro()

  const { user, isAdmin } = storeToRefs(useUsersStore())

  const collects = ref<CollectsCollection>({} as CollectsCollection)
  const lastAddedService = ref<CollectGraphql>({} as CollectGraphql)

  const page = ref<number>(1)
  const collectsLoading = ref<boolean>(false)
  const offset = computed(() => (page.value - 1) * 10)

  const filters = reactive({
    statuses: [] as CollectStatus[],
    sort: 'DESC' as AscDescGraphql,
    client: '',
    search: '',
  })

  watch(filters, async () => {
    page.value = 1
    if (!user.value)
      return
    if (isAdmin.value) {
      await loadBOCollects()
    }
    else {
      await loadServices()
    }
  }, { deep: true })

  watch(page, async () => {
    if (!user.value) {
      return
    }
    if (isAdmin.value) {
      await loadBOCollects()
    }
    else {
      await loadServices()
    }
  })

  const collectsCount = computed(() => collects.value.count)

  const getCollects: ComputedRef<CollectGraphql[] | []> = computed(() => {
    if (collects.value.collection) {
      return collects.value.collection as CollectGraphql[]
    }
    return []
  }) as ComputedRef<CollectGraphql[] | []>

  const getIncidents: ComputedRef<IncidentGraphql[]> = computed(() => {
    if (collects.value.collection) {
      return collects.value.collection.reduce((acc: IncidentGraphql[], collect: CollectGraphql) => {
        collect.incidents.collection.forEach((incident: IncidentGraphql) => acc.push(incident))
        return acc
      }, [])
    }
    return []
  }) as ComputedRef<IncidentGraphql[]>

  const getLastAddedService = computed(() => lastAddedService.value)

  const getCollectsAsOptions = computed(() =>
    collects.value.collection.map(item => ({
      label: item?.__typename,
      value: item?.id,
    })),
  )

  // TODO : Need to change this ASAP
  async function loadBOCollects() {
    const { query } = useGqlMikro()
    const recyclerProducerFilters = { recyclerIds: null, producerIds: null }
    const client = orgStore().getOrg(orgStore().getSelectedOrg)
    if (client) {
      if (client.client_type === 'recycler') {
        recyclerProducerFilters.recyclerIds = [client.id]
      }
      if (client.client_type === 'producer') {
        recyclerProducerFilters.producerIds = [client.id]
      }
    }
    const user = userStore().user
    if (!user)
      return

    collectsLoading.value = true
    const { data } = await query({
      query: BO_COLLECTS_QUERY_LIST,
      variables: { pagination: { limit: 10, offset: offset.value }, filters: { ...recyclerProducerFilters, orderBy: { field: 'startsAt', order: filters.sort }, status: filters.statuses.length > 0 ? filters.statuses : null } },
    })
    collectsLoading.value = false
    collects.value = JSON.parse(JSON.stringify(data.collects))
    return data
  }

  async function loadServices() {
    const { query } = useGqlMikro()
    const user = userStore().user
    if (!user)
      return

    collectsLoading.value = true
    const { data } = await query({
      query: COLLECTS_QUERY_LIST,
      variables: { pagination: { limit: 10, offset: offset.value }, filters: { orderBy: { field: 'startsAt', order: filters.sort }, status: filters.statuses.length > 0 ? filters.statuses : null } },
    })
    collectsLoading.value = false
    collects.value = JSON.parse(JSON.stringify(data.collects))
    return data
  }

  function getServiceById(id: string): Maybe<CollectGraphql> | undefined {
    return collects.value.collection.find(item => item?.id.toString() === id.toString())
  }

  async function updateService(service: any, wasteServiceRawMaterialContainer?: any) {
    const user = userStore().user
    if (!user)
      return

    if (wasteServiceRawMaterialContainer) {
      await apolloClient.mutate({
        mutation: WASTE_SERVICE_RAW_MATERIALS_CONTAINER_UPDATE,
        variables: {
          updates: wasteServiceRawMaterialContainer.map((container: any) => ({
            where: { id: { _eq: container.id } },
            _set: { quantity: container.quantity, unit: container.unit, treatment_code: container.treatment_code },
          })),
        },
      })
    }

    // TODO : ONLY UPDATE licence plate
    const { data: updateData } = await apolloClient.mutate({
      mutation: SERVICES_QUERY_UPDATE,
      variables: { id: service.id, object: {
        license_plate: service.licensePlate,
        transport_price: service.transportPrice ?? 0,
        start_datetime: service.startsAt,
        status: camelToSnakeCase(service.status),
      } },
    })

    if (!updateData)
      return

    const index = collects.value.collection.findIndex(item => item?.id === service.id)
    loadServices()
    // TODO : Wait for backend update for collects
    // collects.value.collection[index] = JSON.parse(JSON.stringify(updateData.update_waste_service_by_pk))
    return updateData
  }
  function updateBSDState(payload: { collectId: string, state: CollectBsdState, tdState: TrackdechetsBsdState }) {
    const { state, tdState, collectId } = payload
    const collect = collects.value.collection.find(item => item?.id.toString() === collectId.toString())
    if (!collect?.BSDs)
      return
    collect.BSDs.collection[0].state = state
    collect.BSDs.collection[0].tdState = tdState
  }
  function updateBSD(payload: { collectId: string, BSD: BsdGraphql }) {
    const { BSD, collectId } = payload
    const collect = collects.value.collection.find(item => item?.id.toString() === collectId.toString())
    collect.BSDs.collection[0] = BSD
  }

  async function createService(service: any) {
    const user = userStore().user
    if (!user)
      return

    const { data: createData } = await apolloClient.mutate({
      mutation: SERVICES_QUERY_INSERT,
      variables: { object: service },
    })

    await loadServices()
    return createData
  }

  async function deleteService(serviceId: string) {
    const user = userStore().user
    if (!user)
      return

    await apolloClient.mutate({
      mutation: WASTE_SERVICE_RAW_MATERIALS_CONTAINER_DELETE,
      variables: { id: serviceId },
    })

    await apolloClient.mutate({
      mutation: SERVICES_QUERY_DELETE,
      variables: { id: serviceId },
    })

    collects.value.collection = collects.value.collection.filter(item => item?.id !== serviceId)
  }

  async function cancelCollect(id: string): Promise<Mutation['cancelCollect']> {
    const { data, errors } = await mutate({
      mutation: CANCEL_COLLECT,
      variables: { input: { collectId: id } as CancelCollectInputGraphql },
    })

    if (errors) {
      throw new Error(errors[0].message)
    }

    const collect = collects.value.collection.find(item => item?.id === id)

    if (!collect)
      throw new Error('Collect not found')

    collect.status = data!.cancelCollect.status

    return data!.cancelCollect
  }

  async function updateServiceProducer(service: CollectGraphql) {
    const user = userStore().user
    if (!user)
      return

    const { data: updateData } = await apolloClient.mutate({
      mutation: SERVICES_QUERY_UPDATE,
      variables: { id: service.id, object: service },
    })

    const index = collects.value.collection.findIndex(item => item?.id === service.id)
    if (index !== -1) {
      collects.value.collection = [
        ...collects.value.collection.slice(0, index),
        updateData.update_waste_service_by_pk,
        ...collects.value.collection.slice(index + 1),
      ]
    }

    lastAddedService.value = updateData.update_waste_service_by_pk
  }

  async function updateServiceWithContainers(service: { id: string, data: any, status: string, waste_service_raw_materials_containers: { data: { fill_rate: any, raw_materials_container_id: string, attachments: any[] }[] } }) {
    const user = userStore().user
    if (!user)
      return

    await loadServices()
    const oldService = getServiceById(service.id)

    const containersOldService = oldService?.collectedContainers.collection.map(container => ({
      container: container?.id,
      fill_rate: container?.fillRate,
      attachment: container?.attachments,
      waste_service_id: service.id,
      id: container?.id,
    }))

    const containersNewService = service.waste_service_raw_materials_containers.data.map(container => ({
      container: container?.raw_materials_container_id,
      fill_rate: container?.fill_rate,
      attachment: container?.attachments,
      waste_service_id: service.id,
    }))

    const containersToDelete = differenceBy(containersOldService, containersNewService, 'container')
    const containersToAdd = differenceBy(containersNewService, containersOldService, 'container')
    const containersToUpdate: any = []

    containersNewService.forEach((newContainer) => {
      const foundContainer = find(containersOldService, (oldContainer) => {
        if (newContainer.container === oldContainer.container) {
          if (newContainer.fill_rate !== oldContainer.fill_rate) {
            return true
          }
          if (newContainer.attachment.length === oldContainer.attachment?.length) {
            for (let i = 0; i < newContainer.attachment.length; i++) {
              if (newContainer.attachment[i].signed_url !== oldContainer.attachment[i].signed_url) {
                return true
              }
            }
          }
          else {
            return true
          }
        }
        return false
      })
      if (foundContainer) {
        containersToUpdate.push({
          id: foundContainer.id,
          fill_rate: newContainer.fill_rate,
          container: newContainer.container,
          attachment: newContainer.attachment,
          waste_service_id: newContainer.waste_service_id,
        })
      }
    })

    if (containersToDelete.length > 0) {
      await apolloClient.mutate({
        mutation: WASTE_SERVICE_RAW_MATERIALS_CONTAINER_DELETE_PRODUCER,
        variables: { ids: containersToDelete.map(container => container.id) },
      })
    }

    if (containersToAdd.length > 0) {
      await apolloClient.mutate({
        mutation: WASTE_SERVICE_RAW_MATERIALS_CONTAINER_INSERT_PRODUCER,
        variables: {
          objects: containersToAdd.map(container => ({
            attachments: container.attachment,
            raw_materials_container_id: container.container,
            fill_rate: container.fill_rate,
            waste_service_id: service.id,
          })),
        },
      })
    }

    if (containersToUpdate.length > 0) {
      await apolloClient.mutate({
        mutation: WASTE_SERVICE_RAW_MATERIALS_CONTAINER_UPDATE_PRODUCER,
        variables: {
          updates: containersToUpdate.map((container: any) => ({
            where: { id: { _eq: container.id } },
            _set: { fill_rate: container.fill_rate, attachments: container.attachment },
          })),
        },
      })
    }

    const dataToSend = {
      id: service.id,
      data: service.data,
      start_datetime: service.start_datetime,
      end_datetime: service.end_datetime,
      status: service.status,
      wp_site_id: service.wp_site_id,
    }

    await updateServiceProducer(dataToSend)
  }

  async function createServiceProducer(service: any) {
    const user = userStore().user
    if (!user)
      return

    const { data: newService } = await apolloClient.mutate({
      mutation: SERVICES_QUERY_INSERT,
      variables: { object: service },
    })

    collects.value.collection = [...collects.value.collection, newService.insert_waste_service_one]
    lastAddedService.value = newService.insert_waste_service_one
    return newService
  }

  function addService(service: any) {
    const serviceToAdd = JSON.parse(JSON.stringify(service))
    lastAddedService.value = serviceToAdd
  }

  async function getSumOfWasteQuantity() {
    const { data } = await apolloClient.query({ query: WASTE_SERVICES_CONTAINERS_SUM_QUANTITY })
    return data.value.waste_service_raw_materials_containers_aggregate.aggregate.sum.quantity
  }

  async function getSumOfCo2() {
    const { data } = await apolloClient.query({ query: WASTE_SERVICES_CONTAINERS_SUM_CO2 })
    return data.value.waste_service_aggregate.aggregate.sum.co2_amount
  }

  async function signBSD(serviceId: string) {
    const { mutate } = useGqlMikro()

    const { data, errors } = await mutate({
      mutation: SIGN_BSD,
      variables: { input: { collectId: Number.parseInt(serviceId) } },
    })
    return { data, errors }
  }

  function resetFilters() {
    filters.statuses = []
    filters.sort = AscDescGraphql.Asc
    filters.client = ''
    filters.search = ''
  }

  return {
    collects,
    filters,
    page,
    collectsLoading,
    collectsCount,
    lastAddedService,
    getCollects,
    getIncidents,
    getLastAddedService,
    getCollectsAsOptions,
    loadServices,
    loadBOCollects,
    resetFilters,
    getServiceById,
    updateService,
    updateBSDState,
    updateBSD,
    createService,
    deleteService,
    cancelCollect,
    updateServiceProducer,
    updateServiceWithContainers,
    createServiceProducer,
    addService,
    getSumOfWasteQuantity,
    getSumOfCo2,
    signBSD,
  }
})
