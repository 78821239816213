<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { VERSION } from '@wastex/common'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import { RotateCcw } from 'lucide-vue-next'
import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { loadUser } from '~/controllers/authentication'
import { hotReload, versionToNumber } from '~/helpers/UtilsHelper'
import { apiStore } from '~/stores/api'
import { userStore } from './stores/user'

useHead({
  title: `WASTEX - ${VERSION}`,
  meta: [{ name: 'description', content: `WasteX - ${VERSION}` }],
})

const appData: any = reactive({
  loading: false,
  interval_ctx: null as any,
  show_modal: false,
  input_buffer: '',
  last_input_timestamp: null,
  last_detected_newline_timestamp: null,
})

function getNavigatorLanguage() {
  let language = 'en'
  if (typeof window !== 'undefined')
    language = navigator.language.substring(0, 2)

  return language
}

async function init() {
  const pathName = new URL(window.location.href).pathname
  const search = new URL(window.location.href).search

  const route = useRoute()
  const router = useRouter()
  const { locale } = useI18n()

  // load user local
  const locale_iso: string = getNavigatorLanguage() // navigator.language.substring(0, 2)
  locale.value = locale_iso

  // load User Info
  appData.loading = true
  await loadUser()

  if (typeof window !== 'undefined') {
    appData.interval_ctx = setInterval(checkVersion, 1000 * 60 * 10) // ask for new version very 10 min
    checkVersion()
  }
  let query: any = route?.query

  if (new URL(window.location.href).searchParams.get('token')) {
    const token = new URL(window.location.href).searchParams.get('token')
    signInWithCustomToken(getAuth(), token)
      .then(() => {
        loadUser()
        router.push(pathName + search)
      })
      .catch((error) => {
        console.error('error', error)
      })
  }
  else {
    if (!query?.go) {
      const urlSearchParams = new URLSearchParams(window.location.search)
      query = Object.fromEntries(urlSearchParams.entries())
    }
    if (query?.go)
      router.push(`/${query?.go}`)
  }
  setDarkMode()

  appData.loading = false
}

function setDarkMode() {
  useDark({
    valueDark: 'light', // toggle this value back to 'dark' to re-enable dark mode
    valueLight: 'light',
  })

  // Remove following line to re-enable dark mode
  document.documentElement.classList.remove('dark')
}

async function hotReloadApp() {
  await hotReload()
}

/* checkVersion */
async function checkVersion() {
  const api = apiStore().getApiClient
  const status = await api.getStatus()
  const frontVersion = versionToNumber(VERSION)
  const serverVersion = versionToNumber(status?.version)
  if (frontVersion < serverVersion)
    appData.show_modal = true
}

onMounted(() => {
  init()
})
</script>

<template>
  <CheckVersionModal
    v-model="appData.show_modal"
    @load-latest-version="hotReloadApp"
  />

  <router-view v-if="!appData.loading && userStore().stores_loaded" />
  <div v-else class="h-full w-full flex flex-col items-center justify-center">
    <img src="/assets/logo-w.png" alt="Wastex logo" class="h-15 w-15 animate-ping">
    <div class="pt-20 text-xl text-gray font-mono">
      {{ userStore().stores_loading_msg }}
    </div>
    <!-- TODO MODAL TO MOVE BELOW -->
    <div class="absolute bottom-20 cursor-pointer align-bottom text-white" @click="hotReloadApp">
      <RotateCcw :size="32" />
      <span>{{ VERSION }}</span>
    </div>
  </div>
</template>
