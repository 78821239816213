import gql from 'graphql-tag'

export const GET_CLIENTS = gql`
query Clients($pagination: PaginationArgs!, $filters: ClientFilterGraphql!) {
  clients(pagination: $pagination, filters: $filters) {
    collection {
      id
      contactFirstName
      contactLastName
      iban
      isConnectedToTrackdechets
      name
      phoneNumber
      siret
      slug
      bic
      documents { 
        collection {
          name
          signedUrl
          type
          id
          extension
          createdAt
        }
      }
      autosignEnabledForTrackdechets
      ... on ProducerGraphql {
        __typename
      }
      ... on RecyclerGraphql {
        __typename
      }
    }
  }
}`

export const UPDATE_CLIENT = gql`
mutation UpdateClient($input: ClientInput!) {
  updateClient(input: $input) {
    id
    name
    slug
    isConnectedToTrackdechets
    autosignEnabledForTrackdechets
    siret
    iban
    contactFirstName
    contactLastName
    bic
    phoneNumber
    documents {
      collection {
        name
        signedUrl
        type
        id
        extension
        createdAt
      }
    }
    address {
      line1
      line2
      postalCode
      city
      country
    }
    sites {
      count
      collection {
        id
        name
        address
      }
    }
  }
}`
