<script setup lang="ts">
import { TriangleAlert } from 'lucide-vue-next'
import type { CollectGraphql } from '~/types/graphql-backend-types/gql-types'

const props = defineProps({
  collect: {
    type: Object as PropType<CollectGraphql>,
    default: () => {},
  },
})

const { t } = useI18n()
const { cancelCollect } = useCollectsStore()
const open = defineModel<boolean>() as Ref<boolean>

const { collect } = toRefs(props)

async function deleteService(id: string) {
  await cancelCollect(id)
  open.value = false
}
</script>

<template>
  <WModal v-model="open">
    <template #title>
      <div class="flex items-center gap-2 mb-2">
        <div class="bg-red-200 p-0.5 rounded">
          <TriangleAlert color="#EF4444" />
        </div>
        <p class="text-xl font-semibold">
          {{ $t("global.delete_service") }}
        </p>
      </div>
    </template>
    <template #default>
      <p>
        {{ t("global.are_you_sure_to_delete") }}
      </p>
    </template>
    <template #footer>
      <div class="flex gap-2 w-full items-center justify-end place-items-center">
        <button class="btn-secondary" @click="open = false">
          {{ $t("global.no") }}
        </button>
        <button class="btn-cancel" @click="deleteService(collect.id)">
          {{ $t("global.yes") }}
        </button>
      </div>
    </template>
  </WModal>
</template>
