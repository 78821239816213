<script setup lang="ts">
interface Props {
  title?: string
  size?: ModalSize
}

type ModalSize = 'sm' | 'md' | 'lg' | 'xl'

const props = withDefaults(defineProps<Props>(), {
  title: 'Modal Title',
  size: 'md',
})
function sizeToPercent(size: ModalSize) {
  switch (size) {
    case 'sm':
      return 'w-1/3'
    case 'md':
      return 'w-1/2'
    case 'lg':
      return 'w-3/5'
    case 'xl':
      return 'w-3/4'
  }
}
const open = defineModel<boolean>()
const { size } = toRefs(props)

function closeModal() {
  open.value = false
}
</script>

<template>
  <Teleport to="body">
    <Transition
      enter-active-class="transition ease-out duration-300"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-if="open" class="fixed inset-0 z-50 overflow-y-hidden" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" @click="closeModal" />

          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

          <div
            :class="sizeToPercent(size)" class="max-h-2xl inline-block align-bottom bg-white rounded-xl text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle"
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="flex">
                <div class="w-full">
                  <h3 id="modal-title" class="text-lg font-medium leading-6 text-gray-900 pb-3">
                    <slot name="title">
                      {{ title }}
                    </slot>
                  </h3>
                  <slot />
                </div>
              </div>
            </div>
            <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>
