import { GET_TREATMENT_CODE, GET_WASTE_CODE_EU } from '~/queries/code-validation'
import type { Query, TreatmentCodeGraphql, WasteCodesGraphql } from '~/types/graphql-backend-types/gql-types'

export const useCodeValidationStore = defineStore('codeValidation', () => {
  const { query } = useGqlMikro()

  const treatmentCodes = ref<TreatmentCodeGraphql[]>([])
  const wasteCodes = ref<WasteCodesGraphql[]>([])

  const getTreatmentCodes = computed(() => treatmentCodes.value)
  const getWasteCodes = computed(() => wasteCodes.value)

  async function loadTreatmentCodes(): Promise<Query['treatmentCodes']> {
    const { data } = await query({
      query: GET_TREATMENT_CODE,
    })
    treatmentCodes.value = data.treatmentCodes
    return data.treatmentCodes
  }

  async function loadWasteCodes(): Promise<Query['wasteCodes']> {
    const { data } = await query({
      query: GET_WASTE_CODE_EU,
    })
    wasteCodes.value = data.wasteCodes
    return data.wasteCodes
  }

  function normalizeCode(code: string): string {
    return code.replace(/\s+/g, '').replace(/\*/g, '')
  }

  function searchCodes(codes: any[], normalizedInput: string): boolean {
    return codes.some(item => normalizeCode(item.code) === normalizedInput)
  }

  function validateUserInput(input: string): boolean {
    const normalizedInput = normalizeCode(input)
    return wasteCodes.value.some(category =>
      category.subCategories.some(subCategory =>
        searchCodes(subCategory.codes, normalizedInput),
      ),
    )
  }

  return {
    getTreatmentCodes,
    getWasteCodes,
    loadTreatmentCodes,
    loadWasteCodes,
    normalizeCode,
    searchCodes,
    validateUserInput,
  }
})
