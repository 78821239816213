import type { FetchResult } from '@apollo/client'
import { defineStore } from 'pinia'
import { useGqlMikro } from '~/composables/useGqlMikro'
import {
  COLLECT_CSV_EXTRACT,
  DELETE_STORED_DOCUMENT,
  GENERATE_WASTE_REGISTER,
  GENERRATE_SIGNED_UPLOAD_URL,
  STORE_DOCUMENT_REFERENCE,
} from '~/queries/documents'
import type {
  DocumentContext,
  DocumentMetaSubtypes,
  DocumentType,
  GenerateSignedUploadUrlInput,
  Mutation,
  StoreDocumentReferenceInput,
} from '~/types/graphql-backend-types/gql-types'

export const useDocumentStore = defineStore('document', () => {
  const { query, mutate } = useGqlMikro()

  async function uploadDocument(payload: { file: File }) {
    const { file } = payload

    const generateFileUrl: GenerateSignedUploadUrlInput = {
      fileName: file.name,
      contentType: file.type,
    }

    // Generate signed upload url
    const signedUploadUrl = await generateSignedUploadUrl(generateFileUrl)

    // Upload file
    await uploadFileToSignedUrl(file, signedUploadUrl.url)
  }
  async function persistDocument(payload: { clientId?: string, file: File, context: { type: DocumentContext, id: string, subtype?: DocumentMetaSubtypes }, type: DocumentType }) {
    const { clientId, file, type, context } = payload
    // Persist document reference
    const filePayload: StoreDocumentReferenceInput = {
      clientId,
      type,
      fileName: file.name,
      externalReference: context.type,
      referenceObjectId: context.id,
    }
    if (context.subtype) {
      filePayload.subtype = context.subtype
    }

    const storeDocumentRef = await storeDocumentReference(filePayload)
    return storeDocumentRef
  }
  async function uploadFileToSignedUrl(file: File, signedUrl: string) {
    try {
      const response = await fetch(signedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return response
    }
    catch (error) {
      console.error('Error uploading file:', error)
      throw error
    }
  }
  async function generateSignedUploadUrl(input: GenerateSignedUploadUrlInput): Promise<Mutation['generateSignedUploadUrl']> {
    const { data, errors } = await mutate({
      mutation: GENERRATE_SIGNED_UPLOAD_URL,
      variables: { input },
    }) as FetchResult<Mutation>

    if (errors) {
      throw new Error(errors[0].message)
    }

    return data!.generateSignedUploadUrl
  }

  async function storeDocumentReference(input: StoreDocumentReferenceInput) {
    const { data, errors } = await mutate({
      mutation: STORE_DOCUMENT_REFERENCE,
      variables: { input },
    }) as FetchResult<Mutation>

    if (errors) {
      throw new Error(errors[0].message)
    }

    return data?.storeDocumentReference
  }

  async function deleteStoredDocument(id: string) {
    const { data, errors } = await mutate({
      mutation: DELETE_STORED_DOCUMENT,
      variables: { input: { id } },
    }) as FetchResult<Mutation>

    if (errors) {
      throw new Error(errors[0].message)
    }

    return data?.deleteStoredDocument
  }

  async function collectCSVExtract(fromDate: any, toDate: any): Promise<any> {
    const csvExtract = await query({
      query: COLLECT_CSV_EXTRACT,
      variables: {
        input: {
          fromDate,
          toDate,
        },
      },
    })
    return csvExtract.data.collectCSVExtract.B64Csv
  }

  async function generateWasteRegister(fromDate: any, toDate: any): Promise<any> {
    const wasteRegister = await query({
      query: GENERATE_WASTE_REGISTER,
      variables: {
        input: {
          fromDate,
          toDate,
        },
      },
    })

    return wasteRegister
  }

  return { uploadDocument, persistDocument, deleteStoredDocument, collectCSVExtract, generateWasteRegister }
})
